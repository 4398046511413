<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx">
      <v-card-title class="primary--text">
        Edit Role
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-sheet class="mb-4" max-width="480" color="transparent">
          <v-skeleton-loader type="text" v-if="loading"></v-skeleton-loader>
          <v-text-field
            v-else
            flat
            outlined
            label="Role Name"
            v-model="name"
            :rules="[rules.required]"
            :error-messages="errorMessageName"
          ></v-text-field>
        </v-sheet>
        <v-sheet class="mb-4" max-width="480" color="transparent">
          <v-skeleton-loader type="paragraph" v-if="loading"></v-skeleton-loader>
          <v-textarea
            v-else
            flat
            outlined
            label="Description"
            v-model="description"
            :rules="[rules.required]"
            :error-messages="errorMessageDes"
          ></v-textarea>
        </v-sheet>

        <h3 class="primary--text large font-weight-medium mb-3 mt-5">
          Modules & Permissions
        </h3>
        <v-row class="pb-4" v-if="dataLoading">
          <v-col cols="12" md="6" v-for="i in 10" :key="i">
            <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
          </v-col>
        </v-row>

        <v-row class="pb-4" v-else>
          <v-col cols="12" md="6" v-for="module in modules" :key="module.id">
            <GroupChecks
              :rolePermissions="rolePermissions"
              :module="module"
              @updatePermissions="updatePermissions"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title v-if="!loading">
        <v-spacer></v-spacer>
        <v-btn color="secondary" depressed @click="goBack" text class="mx-3">
          cancel
        </v-btn>
        <v-btn
          color="primary"
          depressed
          @click="editRole()"
          :loading="editLoading"
        >
          <v-icon left> mdi-content-save </v-icon> Save
        </v-btn>
      </v-card-title>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { fetchAndStorePermissionModules } from "@/services/preloadDataService";
import {
  getRole,
  editRole,
  syncOfflineRolesChanges,
} from "@/services/rolesService";
export default {
  components: {
    GroupChecks: () =>
      import(
        /* webpackChunkName: "component-group-checks" */ "@/components/GroupChecks"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    loading: false,
    dataLoading: false,
    editLoading: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
    errorMessageName: "",
    errorMessageDes: "",
    name: "",
    description: "",
    getRoleReturn: null,
    editRoleReturn: null,
    rolePermissions: [],
    modules: [],
    role: {},
  }),
  async created() {
    if (
      this.$_checkPermission(this.auth.permissions, "Manage Role", "Update")
    ) {
      this.loading = true;
      await syncOfflineRolesChanges();
      this.getRoleReturn = await getRole(this.$route.params.uuid);
      this.role = this.getRoleReturn;
      this.name = this.role.name;
      this.description = this.role.description;
      this.role.role_permissions.forEach((permission) => {
        this.rolePermissions.push(permission.permission_id);
      });
      this.loading = false;

      this.dataLoading = true;
      this.modules = await fetchAndStorePermissionModules();
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({
        name: "PageViewRole",
        params: { uuid: this.role.uuid },
        query: { bc: this.role.name },
      });
    },
    updatePermissions(permissions) {
      permissions.forEach((permission) => {
        if (!permission.value) {
          this.rolePermissions = this.rolePermissions.filter(
            (element) => element !== permission.id
          );
        } else {
          if (!this.rolePermissions.includes(permission.id))
            this.rolePermissions.push(permission.id);
        }
      });
    },
    async editRole() {
      if (this.name.trim() == "") {
        this.errorMessageName = "Required.";
      }

      if (this.description.trim() == "") {
        this.errorMessageDes = "Required.";
      }

      if (this.name && this.description) {
        let xper = [];
        this.rolePermissions.forEach((permission) => {
          xper.push({ permission_id: permission });
        });
        let data = {
          uuid: this.role.uuid,
          name: this.name,
          description: this.description,
          permission_ids: this.rolePermissions,
          role_permissions: xper,
        };
        this.editLoading = true;
        this.editRoleReturn = await editRole(data);
        this.editLoading = false;
        if (this.editRoleReturn == "success") {
          this.goBack();
        }
      }
    },
  },
};
</script>